import { FC } from 'react'
import './ContactInfo.less'
import { useMediaQuery } from 'react-responsive'

const ContactInfo: FC = () => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  const isMobile = useMediaQuery({ maxWidth: 991 })
  return (
    <>
      {isDesktop && (
        <div className='contact-info'>
          <p className='logo-tel'>
            <a href='tel:8776362100' style={{ rotate: undefined, color: '#fdf7e8' }}>
              877-636-2100
            </a>
          </p>
          <p className='logo-email'>
            <a
              href='mailto:customerservice@londonlux.com'
              target='_blank'
              rel='noreferrer'
              style={{ rotate: undefined, color: '#fdf7e8' }}
            >
              customerservice@londonlux.com
            </a>
          </p>
        </div>
      )}
      {isMobile && (
        <div className='contact-info-mob'>
          <p className='logo-tel-mob'>
            <a href='tel:8776362100' style={{ rotate: undefined, color: '#fdf7e8' }}>
              877-636-2100
            </a>
          </p>
          <p className='logo-email-mob'>
            <a
              href='mailto:customerservice@londonlux.com'
              target='_blank'
              rel='noreferrer'
              style={{
                rotate: undefined,
                color: '#fdf7e8',
              }}
            >
              customerservice@londonlux.com
            </a>
          </p>
        </div>
      )}
    </>
  )
}

export { ContactInfo }
