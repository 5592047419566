import { Suspense, FC } from 'react'
import { Layout, Space, Dropdown, Menu, Button } from 'antd'
import './DefaultLayout.less'
import { ContactInfo } from '../contact/ContactInfo'
import { TopNavigation } from '../menu/TopNavigation'
import { Outlet, NavLink, Link } from 'react-router-dom'
import { ContactUs } from '../contact/ContactUs'
import { FooterContent } from '../footer/FooterContent'
import { useMediaQuery } from 'react-responsive'
import { Logo } from '../logo/Logo'
import { Loader } from '../../containers'

const menu = (
  <Menu style={{ background: '#122222', padding: '28px 24px 40px 24px' }}>
    <Menu.Item key={'company'} className='dropdown-item'>
      <NavLink to={'/company'} className='dropdown-link'>
        COMPANY
      </NavLink>
    </Menu.Item>
    <Menu.Item key={'quality'} className='dropdown-item'>
      <NavLink to={'/quality'} className='dropdown-link'>
        QUALITY
      </NavLink>
    </Menu.Item>
    <Menu.Item key={'partners'} className='dropdown-item'>
      <NavLink to={'/partners'} className='dropdown-link'>
        PARTNERS
      </NavLink>
    </Menu.Item>
    <Menu.Item key={'operations'} className='dropdown-item'>
      <NavLink to={'/operations'} className='dropdown-link'>
        OPERATIONS
      </NavLink>
    </Menu.Item>
    {/*<Menu.Item key={'contacts'} className='dropdown-item'>*/}
    {/*  <NavLink to={'/contacts'} className='dropdown-link'>*/}
    {/*    CONTACTS*/}
    {/*  </NavLink>*/}
    {/*</Menu.Item>*/}
    <Menu.Item key={'contactInfo'} className='dropdown-item'>
      <ContactInfo />
    </Menu.Item>
    <Menu.Item key={'contactUs'} className='dropdown-item-btn'>
      <NavLink to={'/contacts'} className='dropdown-link'>
        <ContactUs />
      </NavLink>
    </Menu.Item>
  </Menu>
)

const DefaultLayout: FC = () => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return (
    <Layout style={{ minHeight: '100vh', width: '100%' }}>
      {isDesktop ? (
        <Layout.Header
          style={{
            paddingTop: '0',
            paddingBottom: '0',
            paddingLeft: '0',
            paddingRight: '0',
            height: 'fit-content',
            lineHeight: 'inherit',
            marginBottom: '-1px',
            position: 'fixed',
            top: '0',
            left: '0',
            zIndex: 100,
            width: '100%',
          }}
        >
          <Space
            align={'center'}
            size={'large'}
            direction={'horizontal'}
            style={{ display: 'flex', justifyContent: 'space-between', background: '#122222' }}
          >
            <ContactInfo />
            <TopNavigation />
            <ContactUs />
          </Space>
        </Layout.Header>
      ) : (
        <Layout.Header
          style={{
            padding: '18px 32px',
            height: '80px',
            lineHeight: 'inherit',
            marginBottom: '-1px',
            position: 'fixed',
            top: '0',
            left: '0',
            zIndex: 100,
            width: '100%',
            alignItems: 'center',
            background: '#122222',
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Link to={'/'}>
            <Logo width={'136.97px'} height={'44px'} />
          </Link>

          <Dropdown
            overlay={menu}
            trigger={['click']}
            className='dropdown'
            overlayStyle={{
              width: '100%',
              top: '80px !important',
              background: '#122222',
              position: 'fixed',
            }}
            overlayClassName='menu-dropdown'
          >
            <Button className='btn-burger'>
              <span />
            </Button>
          </Dropdown>
        </Layout.Header>
      )}

      <Layout.Content>
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </Layout.Content>
      <Layout.Footer style={{ background: '#091513', padding: '0', display: 'flex', justifyContent: 'center' }}>
        <FooterContent />
      </Layout.Footer>
    </Layout>
  )
}

export { DefaultLayout }
