import { FC, ReactNode } from 'react'
import { Col, Row, Typography } from 'antd'
import { Logo } from '../logo/Logo'
import './FooterContent.less'
import { useMediaQuery } from 'react-responsive'

const FooterContent: FC = () => {
  const isDesktop = useMediaQuery({ minWidth: 1091 })
  const isMobile = useMediaQuery({ maxWidth: 1090 })
  const items = [
    {
      title: 'Customer experience',
      name: 'Toll free',
      tel: '877-636-2100',
      email: 'customerservice@londonlux.com',
    },
    {
      title: 'Brands & licensing',
      name: 'Amanda Keller',
      tel: '914-636-2100 x525',
      email: 'akeller@londonlux.com',
    },
    {
      title: 'Product & innovation inquiries',
      name: 'Steve Schwartz',
      tel: '914-636-2100 x662',
      email: 'sschwartz@londonlux.com',
    },
    {
      title: 'Marketing Sales & Distribution Opportunities',
      name: 'Moshe Abehsera',
      tel: '914-636-2100 x524',
      email: 'mabehsera@londonlux.com',
    },
  ]
  return (
    <>
      {isDesktop && (
        <Row
          wrap
          style={{ width: '100%', padding: '39px 32px 30px 50px', maxWidth: '1920px' }}
          justify={'space-between'}
        >
          <Col span={4}>
            <Logo width={'175px'} height={'56px'} color={'#FDF7E8'} />
            <Typography.Paragraph style={{ marginTop: '57px', fontSize: '13px', lineHeight: '15px', color: '#FDF7E8' }}>
              © 2021 - London Luxury, LLC
            </Typography.Paragraph>
          </Col>
          {items.map((el, index) => {
            return (
              <Col
                key={index}
                span={5}
                style={{
                  justifyContent: ' space-between',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography.Paragraph className='footer-title' style={{ marginBottom: '17px' }}>
                  {el.title}
                </Typography.Paragraph>
                <span>
                  <Typography.Paragraph className='footer-paragraph' style={{ marginBottom: '5px' }}>
                    {el.name}
                  </Typography.Paragraph>
                  <Typography.Paragraph className='footer-paragraph' style={{ marginBottom: '4px' }}>
                    {el.tel}
                  </Typography.Paragraph>
                  <Typography.Text className='footer-text'>
                    <a
                      href={`mailto:${el.email}`}
                      target='_blank'
                      rel='noreferrer'
                      style={{ rotate: undefined, color: '#fdf7e8' }}
                    >
                      {' '}
                      {el.email}
                    </a>
                  </Typography.Text>
                </span>
              </Col>
            )
          })}
        </Row>
      )}
      {isMobile && (
        <Row wrap style={{ width: '100%', padding: '24px 27px 25px' }}>
          <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Logo width={'75px'} height={'24px'} />
            <Typography.Paragraph
              style={{
                fontSize: '13px',
                lineHeight: '15px',
                color: '#FDF7E8',
                marginBottom: '0',
                alignSelf: 'align-self: center',
              }}
            >
              © 2021 - London Luxury
            </Typography.Paragraph>
          </Col>
        </Row>
      )}
    </>
  )
}

export { FooterContent }
