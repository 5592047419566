import { FC } from 'react'
import { Menu, Layout } from 'antd'
import { withNavigation } from '../../containers/Routing'
import '../layout/index.less'
import './TopNavigation.less'
import { Logo } from '../logo/Logo'
import { useMediaQuery } from 'react-responsive'

const TopMenu: FC = ({ children }) => {
  // const isDesktop = useMediaQuery({ minWidth: 577 })
  // const isMobile = useMediaQuery({ maxWidth: 576 })
  const isDesktop = useMediaQuery({ minWidth: 992 })
  const isMobile = useMediaQuery({ maxWidth: 991 })
  // const isDesktop = useMediaQuery({ minWidth: 901 })
  // const isMobile = useMediaQuery({ maxWidth: 900 })
  return (
    <>
      {isDesktop && (
        <Menu
          className='menu-items'
          theme={'dark'}
          mode='horizontal'
          style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}
        >
          {children}
        </Menu>
      )}
    </>
  )
}
const TopNavigation = withNavigation(TopMenu)
export { TopNavigation }
