import { FC, lazy, ReactNode } from 'react'
import { NavLink, Route, Routes, useLocation } from 'react-router-dom'
import { DefaultLayout } from '../components/layout/DefaultLayout'
import { Menu, MenuProps } from 'antd'
import { RoutesProps } from 'react-router'
import { Logo } from './../components/logo/Logo'

const Home = lazy(() => import('./../pages/home'))
const ContactUs = lazy(() => import('../pages/contact-us'))
const Company = lazy(() => import('../pages/company'))
const Quality = lazy(() => import('../pages/quality'))
const Partners = lazy(() => import('../pages/partners'))
const Operations = lazy(() => import('../pages/operations'))
const Contacts = lazy(() => import('../pages/contact-us'))
const LogoIcon = lazy(() => import('./../pages/home'))
type RouteNavigationMap = {
  [key: string]: { component: FC; renderItem: string | FC }
}

const routeMap: RouteNavigationMap = {
  '/company': {
    component: Company,
    renderItem: 'Company',
  },
  '/quality': {
    component: Quality,
    renderItem: 'Quality',
  },
  '/': {
    component: Home,
    renderItem: Logo,
  },
  '/partners': {
    component: Partners,
    renderItem: 'Partners',
  },

  '/operations': {
    component: Operations,
    renderItem: 'Operations',
  },
  // '/contacts': {
  //   component: ContactUs,
  //   renderItem: 'Contacts',
  // },
}

function withNavigation(Wrapped: FC<MenuProps>): FC<MenuProps> {
  return props => {
    const { pathname } = useLocation()
    return (
      <Wrapped {...props} defaultSelectedKeys={[pathname ?? '/']}>
        {Object.entries(routeMap).map(([path, { renderItem }]) => (
          <Menu.Item key={path} className='menu-item'>
            <NavLink to={path}>{typeof renderItem === 'string' ? renderItem.toUpperCase() : renderItem({})}</NavLink>
          </Menu.Item>
        ))}
      </Wrapped>
    )
  }
}

function withRoutes(Wrapped: FC): FC<RoutesProps> {
  return props => (
    <Wrapped {...props}>
      {Object.entries(routeMap).map(([path, { component }]) => {
        const Page = component
        return <Route key={path} path={path} element={<Page />} />
      })}
    </Wrapped>
  )
}

const InnerRouter: FC = ({ children }) => (
  <Routes>
    <Route path={''} element={<DefaultLayout />}>
      {children}
      <Route path={'/contacts'} element={<ContactUs />} />
    </Route>
  </Routes>
)
const Routing = withRoutes(InnerRouter)
export { Routing, withNavigation }
